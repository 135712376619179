import styled from 'styled-components'
import { variant } from 'styled-system'
import { defaultValues } from 'ui/acro/typography/P'

const Input = styled('input')(props => (
  variant({
    variants: {
      primary: {
        ...defaultValues,
        color: props.theme.colors.grey6,
        width: '100%',
        maxHeight: '50px',
        background: props.theme.colors.base,
        borderColor: props.theme.colors.grey3,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        marginTop: '8px',
        padding: '12px 16px 11px 16px',
        transition: 'background .25s ease-in-out, border .25s ease-in-out',
        '&:focus': {
          borderColor: props.theme.colors.primary,
          outline: '0',
        },
        '&.error': {
          borderColor: props.theme.colors.errorDefault
        }
      }
    }
  })
))

Input.defaultProps = {
  variant: 'primary'
}

export default Input
