import styled from 'styled-components'

import Text from './Text'

const defaultValues = {
  as: 'h2',
  fontSize: ['20px', '24px'],
  fontFamily: 'Arquitecta',
  letterSpacing: 3,
  lineHeight: '105%',
  fontWeight: 'heavy',
  color: 'grey6',
  textTransform: 'uppercase'
}

const Subtitle = styled(Text)``

Subtitle.defaultProps = {
  ...defaultValues
}

export {
  defaultValues,
  Subtitle
}
