import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveSVG } from '../../elements'
import { Close } from 'images'

const CloseIcon = ({ absolute, closeColor = 'primary', onClose }) => (
  <ResponsiveSVG
    position={absolute && 'absolute'}
    onClick={onClose}
    onKeyPress={onClose}
    tabIndex={0}
    cursor='pointer'
    top={absolute && ['18px', null, '22px']}
    right={absolute && ['18px', null, '22px']}
    zIndex={1}
    aria-label='Close Modal'
    height={['14px']}
    width={['14px']}
    fill={closeColor}
    SVG={Close}
  />
)

CloseIcon.propTypes = {
  absolute: PropTypes.bool,
  onClose: PropTypes.func
}

export default CloseIcon
