import React from 'react'
import PropTypes from 'prop-types'

import { Button, Container, Flex } from '../../elements'

import BannerBody from './BannerBody'
import BannerTitle from './BannerTitle'

// We use vw for padding due to a bug when rotating a display (Android and iPhone) which causes the % calculation to be incorrect after going from landscape to portrait.

const Banner = ({ button1, button2, id, body, title, ...rest }) => (
  <Container
    bg='black'
    minHeight={[null, null, '212px']}
    width='100%'
    py={['32px', null, '0px']}
    mb='1px'
    borderWidth='1px'
    borderStyle='solid none'
    borderColor='primary'
    centerProps={{
      flexDirection: ['column', null, 'row'],
      my: 'auto'
    }}
    {...rest}
  >
    <Flex
      flexDirection={['column', null, 'row']}
      maxWidth={['100%', null, '70%', '58%']}
    >
      <Flex flexDirection='column' alignItems={['center', null, 'flex-start']}>
        {title && <BannerTitle>{title}</BannerTitle>}
        {body && <BannerBody>{body}</BannerBody>}
      </Flex>
    </Flex>

    <Flex
      flexDirection={['column', null, null, 'row']}
      justifyContent='flex-end'
      alignItems={[null, null, 'stretch']}
      width={['100%', null, '500px']}
      pl={[null, null, '16px']}
      my={['auto']}
      flex={1}
    >
      {button1 && (
        <Button
          as='a'
          className='unstyled'
          href={button1.link}
          variant={'primary-inverted'}
          mr={[null, null, null, '16px']}
          mb={['16px', '16px', '16px', '0px']}
          whiteSpace='nowrap'
          id={`banner_${id}_button_1`}
          aria-label={button1.text}
          onClick={() => {
            typeof button1.onClickBtn1 === 'function' && button1.onClickBtn1()
          }}
        >
          {button1.text}
        </Button>
      )}
      {button2 && (
        <Button
          as='a'
          className='unstyled'
          href={button2.link}
          variant='secondary-inverted'
          whiteSpace='nowrap'
          id={`banner_${id}_button_2`}
          aria-label={button2.text}
        >
          {button2.text}
        </Button>
      )}
    </Flex>
  </Container>
)

Banner.propTypes = {
  button1: PropTypes.object,
  button2: PropTypes.object,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string
}

export default Banner
