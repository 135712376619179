//
// Mobile: Full page background image with content bottom-aligned
// Desktop: Split view with image on left and content on right (center-aligned)
//
import React from 'react'
import PropTypes from 'prop-types'

import { ContentBox, Box, Flex, FlexFull, Image } from '../elements'

const Landing = ({ image, border, children }) => {
  return (
    <FlexFull
      scrollDesktop={false}
      alignItems={['flex-end', null, 'center']}
      pb={['40px', null, '0px']}
      background={[
        `linear-gradient(180deg, rgba(0, 0, 0, 0) -5%, #000000 82%), url(${image}) no-repeat top/cover`,
        null,
        'none'
      ]}
      borderTopWidth={border && ['0px', null, '1px']}
      borderTopStyle={border && 'solid'}
      borderTopColor='grey3'
    >
      {/* Desktop only */}
      <Box display={['none', 'none', 'inherit']} width='50%' height='100%'>
        <Image src={image} objectFit='cover' />
      </Box>

      <Flex
        justifyContent='center'
        width={['100%', null, '50%']}
        mx={['auto', null, '0px']}
        px={['0px', null, '6vw']} // Padding is part of 50% width
      >
        <ContentBox mt={['40px', null, '0px']}>{children}</ContentBox>
      </Flex>
    </FlexFull>
  )
}

Landing.propTypes = {
  image: PropTypes.string.isRequired,
  border: PropTypes.bool,
  children: PropTypes.node
}

export default Landing
