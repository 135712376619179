import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { typography } from 'styled-system'

import ReadMoreLegacy from './ReadMoreLegacy'

const ReadMoreWrapper = styled.div`
  ${typography}
  
  .read-more-content {
    overflow: hidden;
    transition: all .25s ease-in-out;
    margin-bottom: 20px;
  }
`

const ReadMore = ({ id, children, ...rest }) => (
  <ReadMoreWrapper {...rest} className='read-more'>
    <ReadMoreLegacy initialHeight={175} setMinHeight={false} id={id}>
      {children}
    </ReadMoreLegacy>
  </ReadMoreWrapper>
)

ReadMore.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
}

export default ReadMore
