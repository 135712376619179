import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from '../ContentModal'
import TermsText from './TermsTextUS'

import TermsTextCanada from './TermsTextCanada'

import Body from './Body'

const TermsModal = ({ useModal, country }) => {
  return (
    <ContentModal useModal={useModal} header='Terms of Use'>
      <Body>{country === 'CA' ? <TermsTextCanada /> : <TermsText />}</Body>
    </ContentModal>
  )
}

TermsModal.propTypes = {
  useModal: PropTypes.object.isRequired
}

export default TermsModal
