import React from 'react'
import PropTypes from 'prop-types'

import Responsive from './Responsive'

const DesktopOnly = ({ children, ...rest }) => (
  <Responsive show={[false, false, true]} {...rest}>
    {children}
  </Responsive>
)

DesktopOnly.propTypes = {
  children: PropTypes.node
}

export default DesktopOnly
