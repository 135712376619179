import React from 'react'
import PropTypes from 'prop-types'

import { Banner } from './Banner'

export const PromoBanner = ({
  btnText,
  btnLink,
  cta,
  productId,
  id,
  body,
  title,
  onClickBtn1,
  ...rest
}) => {
  const productParam = productId ? `?product_id=${productId}` : ''
  return (
    <Banner
      title={title}
      body={body}
      id={id}
      button1={{
        text: cta || 'Start my free trial',
        link: `/subscribe`,
        onClickBtn1: onClickBtn1
      }}
      button2={{
        text: btnText,
        link: btnLink
      }}
      {...rest}
    />
  )
}

PromoBanner.propTypes = {
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
  cta: PropTypes.string,
  id: PropTypes.string.isRequired,
  productId: PropTypes.number,
  body: PropTypes.string,
  title: PropTypes.string
}

export default PromoBanner
