import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Box from './Box'
import { Caption } from '../typography'

const StyledHR = styled(Box)`
  position: relative;
  text-align: center;
  & > span {
    background: ${props => props.theme.colors.base};
    color: ${props => props.theme.colors.grey4};
  }
  &:before {
    content: '';
    background: ${props => props.theme.colors.grey3};
    position: absolute;
    left: 0;
    top: 54%;
    width: 100%;
    height: 1px;
    z-index: -1;
  }
`

const HRText = ({ children, ...rest }) => (
  <StyledHR {...rest}>
    <Caption variant='bold' as='span' lineHeight='0' px={['4px', '8px']}>{children}</Caption>
  </StyledHR>
)

HRText.propTypes = {
  children: PropTypes.node
}

export default HRText
