import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import FAQItem from './FAQItem'

const FAQ = ({ faqItems }) => {
  const [selected, setSelected] = useState(null)

  const handleSelect = (index) => {
    if (selected !== index) {
      setSelected(index)
    } else {
      setSelected(null)
    }
  }

  return (
    <Fragment>
      {faqItems.map((item, index) => (
        <FAQItem key={index} selected={selected === index} handleSelect={handleSelect} index={index} {...item} />
      ))}
    </Fragment>
  )
}

FAQ.propTypes = {
  faqItems: PropTypes.array
}

export default FAQ
