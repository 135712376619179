import React from 'react'
import PropTypes from 'prop-types'

import ContentModal from '../ContentModal'
import PrivacyText from './PrivacyText'
import Body from './Body'

const PrivacyModal = ({ useModal }) => {
  return (
    <ContentModal
      useModal={useModal}
      header='Privacy Policy'
    >
      <Body>
        <PrivacyText />
      </Body>
    </ContentModal>
  )
}

PrivacyModal.propTypes = {
  useModal: PropTypes.object.isRequired
}

export default PrivacyModal
