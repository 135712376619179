import styled from 'styled-components'
import { color, layout, space } from 'styled-system'

const HR = styled.div`
  ${space}
  ${color}
  ${layout}
  height: 1px;
  grid-area: ${(props) => props.area || ''};
  background-color: ${(props) => props.theme.colors.grey2};
`

export default HR
