import React from 'react'
import PropTypes from 'prop-types'
import { Field as FormikField, ErrorMessage } from 'formik'

import { Collapse } from 'ui/acro/animations'
import { FieldSet, FieldError, Input, Label } from 'ui/acro/elements'

const TextInput = ({
  children,
  label,
  name,
  autoComplete,
  autoFocus,
  errors,
  touched,
  handleChange,
  placeHolder
}) => {
  const hasError = !!(errors[name] && touched[name])

  return (
    <FieldSet>
      <Label>
        {label && label}

        <Input
          as={FormikField}
          type='text'
          name={name}
          className={hasError && 'error'}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onKeyUp={handleChange}
          placeHolder={placeHolder}
        />
      </Label>

      <Collapse isOpened={hasError}>
        <ErrorMessage name={name} component={FieldError} />
      </Collapse>

      {children}
    </FieldSet>
  )
}

TextInput.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  handleChange: PropTypes.func,
  // Formik props:
  errors: PropTypes.object,
  touched: PropTypes.object
}

export default TextInput
