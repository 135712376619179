import React from 'react'
import PropTypes from 'prop-types'

import { Box } from 'ui/acro/elements'

const Body = ({ children }) => (
  <Box
    borderRadius='8px'
    overflow='hidden'
  >
    <Box
      p='20px'
      overflowY='scroll'
      bg='base'
      maxHeight={['calc(65vh - 73px)', 'calc(65vh - 77px)']}
    >
      {children}
    </Box>
  </Box>
)

Body.propTypes = {
  children: PropTypes.node
}

export default Body
