import styled from 'styled-components'
import { border, layout, space } from 'styled-system'

const Avatar = styled.img`
  ${border}
  ${layout}
  ${space}
`

Avatar.defaultProps = {
  borderRadius: '100%',
  width: [108, 162],
  height: [108, 162]
}

export default Avatar
