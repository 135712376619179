import styled from 'styled-components'
import { compose, flexbox, typography, system } from 'styled-system'

import Box from 'ui/acro/elements/Box'

// We set a defaultValues object to contain all of the defaultProps for our various Typographic Elements.
// This allows us to import these values and destructure them into other styled-components that need accesss to the Typography, but are not using Text as a base.
// For example, a styled.input might use the defaultValues from P.js in order to style the text inside of an input box the same as our existing <P/> elements.

const Text = styled(Box)(
  compose(
    flexbox,
    typography
  ),
  system({
    cursor: true,
    textTransform: true,
    whiteSpace: true,
    wordBreak: true
  })
)

export default Text
