import styled from 'styled-components'
import { border, layout, space } from 'styled-system'

const Image = styled.img`
  ${border}
  ${layout}
  ${space}
  grid-area: ${props => props.area || ''};
  object-fit: ${props => props.objectFit};
`

Image.defaultProps = {
  maxWidth: '100%'
}

export default Image
