import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const NameList = ({ people }) => {
  const renderNames = () => {
    if (Array.isArray(people)) {
      return people.map((person, index) => {
        if (index < people.length - 1) {
          return `${person.firstName} ${person.lastName}, `
        } else {
          return `${person.firstName} ${person.lastName}`
        }
      })
    } else {
      return null
    }
  }

  return <Fragment>{renderNames()}</Fragment>
}

NameList.propTypes = {
  people: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  ).isRequired
}

export default NameList
