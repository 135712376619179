import styled from 'styled-components'
import { alignSelf, color, justifySelf, layout, space, position } from 'styled-system'

const GridItem = styled.div`
  ${alignSelf}
  ${color}
  ${justifySelf}
  ${layout}
  ${position}
  grid-area: ${props => props.area || ''};
  ${space}
`

export default GridItem
