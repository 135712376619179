import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { Header } from '../typography/Header'

const CloseFlex = styled('div')(
  {
    '&:focus': {
      outline: 'none'
    }
  },
  css({
    cursor: 'pointer',
    position: 'absolute',
    top: '15px',
    right: '15px',
    height: ['30px', null, '36px'],
    width: ['30px', null, '36px'],
    background: 'rgba(152, 152, 152, 0.85)',
    '@supports (backdrop-filter: blur(40px))': {
      background: 'rgba(152, 152, 152, 0.4)',
      backdropFilter: 'blur(40px)',
    },
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    zIndex: 1
  })
)

const CloseCircle = ({ onClose }) => {
  return (
    <CloseFlex
      onClick={onClose}
      onKeyPress={onClose}
      tabIndex='0'
      aria-label='Close'
    >
      <Header color='white' fontSize={['30px', null, '42px']}>&times;</Header>
    </CloseFlex>
  )
}

CloseCircle.propTypes = {
  onClose: PropTypes.func
}

export default CloseCircle
