import React from 'react'
import PropTypes from 'prop-types'

import { Flex, ResponsiveSVG } from '../elements'
import { Headline, P } from '../typography'

const Greeting = ({ icon, title, body }) => {
  const svgSize = ['45px', '60px']

  return (
    <Flex flexDirection='column' alignItems='center'>
      {icon && (
        <ResponsiveSVG
          height={svgSize}
          width={svgSize}
          SVG={icon}
          fill='primary'
        />
      )}
      <Headline mt={['16px', '20px']} mb={['10px']} textAlign='center'>
        {title}
      </Headline>
      <P textAlign='center'>{body}</P>
    </Flex>
  )
}

Greeting.propTypes = {
  icon: PropTypes.func,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}

export default Greeting
