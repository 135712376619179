import React from 'react'
import PropTypes from 'prop-types'

import Box from 'ui/acro/elements/Box'
import Flex from 'ui/acro/elements/Flex'
import HR from 'ui/acro/elements/HR'
import { Subtitle } from 'ui/acro/typography/Subtitle'

import CloseIcon from './CloseIcon'

const Header = ({ header, close }) => (
  <Box position='sticky' top={0}>
    <Flex
      my='0px'
      padding='15px'
      justifyContent='space-between'
      alignItems='center'
    >
      <Subtitle>{header}</Subtitle>
      <CloseIcon onClose={close} />
    </Flex>
    <HR />
  </Box>
)

Header.propTypes = {
  header: PropTypes.node,
  close: PropTypes.func
}

export default Header
