import React, { Fragment, forwardRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Fade } from 'ui/acro/animations'
import { CSSTransition } from 'react-transition-group'

import { useOutsideClick } from 'hooks'

const Overlay = styled('div')((props) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  transition: 'background 0.15s ease-in-out',
  zIndex: 10001,
  '&.overlay-appear-done': {
    background: props.transparent ? '' : 'rgba(0, 0, 0, 0.45)'
  }
}))

// This component renders a modal overlay and any passed through children components.
// It renders the overlay and fades in whatever is given to it. It provides no other styling. This is useful for video overlays, etc.
// It requires any children to implement a proper useModal.close function
// It does so by appending a Portal element to the document.body that is adjacent to the root div.
// This allows the Overlay to supersede any other z-indices that are set.

// For more information on createPortal, see: https://reactjs.org/docs/portals.html

const Modal = forwardRef(
  ({ active, overlay, close, children, transparent, ...rest }, ref) => {
    delete rest.open // Remove useModal.open from props as it's not needed here

    useOutsideClick(ref, close, active)

    return (
      <Fragment>
        {active &&
          ReactDOM.createPortal(
            <CSSTransition
              in={overlay}
              timeout={150}
              classNames='overlay'
              component={null}
              appear
            >
              <Overlay transparent={transparent}>
                <Fade inTrigger={overlay} duration={200} {...rest}>
                  {children}
                </Fade>
              </Overlay>
            </CSSTransition>,
            document.body
          )}
      </Fragment>
    )
  }
)

// See hooks/useModal for props: ref, active, overlay, open, close
Modal.propTypes = {
  active: PropTypes.bool,
  overlay: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.node
}

export default Modal
