import React from 'react'
import PropTypes from 'prop-types'
import { Field as FormikField, ErrorMessage } from 'formik'

import { Collapse } from 'ui/acro/animations'
import { FieldSet, FieldError, Input, Label } from 'ui/acro/elements/forms'

const EmailInput = ({
  children,
  autoFocus,
  handleChange,
  TakenError,
  errors,
  touched,
  placeHolder,
  isLabelRequired = true
}) => {
  const hasError = !!(touched.email && errors.email)

  return (
    <FieldSet>
      <Label>
        {isLabelRequired && 'Email'}
        <Input
          as={FormikField}
          type='email'
          name='email'
          id='email'
          inputMode='email'
          autoComplete='email'
          autoFocus={autoFocus}
          className={hasError && 'error'}
          onKeyUp={handleChange}
          placeHolder={placeHolder}
        />
      </Label>

      <Collapse isOpened={hasError}>
        <ErrorMessage name='email'>
          {(message) =>
            (message == 'has already been taken' && TakenError) || (
              <FieldError>{message}</FieldError>
            )
          }
        </ErrorMessage>
      </Collapse>

      {children}
    </FieldSet>
  )
}

EmailInput.propTypes = {
  children: PropTypes.node,
  autoFocus: PropTypes.bool,
  handleChange: PropTypes.func,
  TakenError: PropTypes.node,
  // Formik props:
  errors: PropTypes.object,
  touched: PropTypes.object
}

export default EmailInput
