import styled from 'styled-components'
import { variant } from 'styled-system'

import Text from './Text'

// Proxima Nova font weight values only include 900, 700, 600, and 400.
// We will use 400 for regular, 600 (heavy) for semibold, and 700 (black) for bold.

const defaultValues = {
  as: 'p',
  fontSize: ['13px', '14px'],
  fontFamily: 'Proxima',
  letterSpacing: 0,
  lineHeight: ['165%', '170%'],
  fontWeight: 'regular',
}

const Metadata = styled(Text)(props => (
  variant({
    variants: {
      semibold: {
        color: props.theme.colors.grey5,
        lineHeight: '110%',
        fontWeight: 'heavy'
      },
      bold: {
        color: props.theme.colors.grey5,
        lineHeight: '110%',
        fontWeight: 'black'
      }
    }
  })
))

Metadata.defaultProps = {
  ...defaultValues
}

export {
  defaultValues,
  Metadata
}
