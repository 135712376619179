import styled from 'styled-components'
import Text from './Text'

const defaultValues = {
  fontSize: ['18px', '20px'],
  fontFamily: 'Arquitecta',
  letterSpacing: 3,
  lineHeight: '105%',
  fontWeight: 'heavy',
  textTransform: 'uppercase'
}

const CTA = styled(Text)``

CTA.defaultProps = {
  ...defaultValues
}

export {
  CTA,
  defaultValues
}
