import React from 'react'
import PropTypes from 'prop-types'

import Flex from './Flex'
import { componentHeights as heights } from 'ui/acro/themes'

// 
// This does not quite work for iOS Safari due to the bottom chrome nav
// Solution: Get height from window.innerHeight via useEffect and resize event listener
// Note: We will have to refactor the #react-body style in GlobalStyle.js as it uses 100vh
// 
const FlexFull = ({ children, scrollDesktop, ...rest }) => {
  const containerHeight = (format) => {
    // Assumes AlertBar is not shown
    return `calc(100vh - ${heights.navbar[format]})`
  }

  // If scrollDesktop is false, set a fixed height
  const height = scrollDesktop ? [] : [null, null, containerHeight('desktop')]

  return (
    <Flex
      height={height}
      minHeight={[containerHeight('mobile'), null, containerHeight('desktop')]}
      {...rest}
    >
      {children}
    </Flex>
  )
}

FlexFull.propTypes = {
  children: PropTypes.node,
  scrollDesktop: PropTypes.bool
}

FlexFull.defaultProps = {
  scrollDesktop: true
}

export default FlexFull
