import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Box from './Box'
import { Checkmark } from 'images'

const CheckContainer = styled(Box)`
  border: 1px solid;
  border-radius: 4px;
  transition: all .25s ease-in-out;
  background-color: ${props => props.selected ? props.theme.colors.primary : 'transparent'};
  border-color: ${props => props.selected ? props.theme.colors.primary : (props.hovered ? props.theme.colors.grey7 : props.theme.colors.grey3)};

  > svg {
    stroke: ${props => props.selected ? props.theme.colors.base : 'transparent'};
  }

  &:disabled {
    border-color: ${props => props.theme.colors.grey1};
    background-color: ${props => props.theme.colors.grey1};

    > svg {
      stroke: ${props => props.selected ? props.theme.colors.grey3 : props.theme.colors.grey1};
    }
  }

  &:focus {
    outline: none;
  }
`

const Checkbox = ({ disabled, hovered, selected, height, width, ...rest }) => {
  return (
    <CheckContainer
      as='button'
      height={height || '24px'}
      width={width || '24px'}
      hovered={hovered}
      disabled={disabled}
      selected={selected}
      px='3px'
      py='5px'
      {...rest}
    >
      <Checkmark />
    </CheckContainer>
  )
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  hovered: PropTypes.bool,
  selected: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array
  ])
}

export default Checkbox
