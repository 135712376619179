import styled from 'styled-components'
import { layout, position } from 'styled-system'

const ButtonBase = styled('button')`
  ${position}
  ${layout}
  position: absolute;
  width: 44px;
  height: 44px;
  padding: 10px;
  background: ${props => props.theme.colors.base};
  border: 1px solid ${props => props.theme.colors.base};
  border-radius: 50%;
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%);
  transition: all .25s ease-in-out;
  z-index: 1;

  &:disabled {
    opacity: 0;
    visibility: hidden;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid ${props => props.theme.colors.primary};
  }
`

export default ButtonBase