import React from 'react'
import PropTypes from 'prop-types'

import Flex from './Flex'
import ResponsiveSVG from './ResponsiveSVG'

import { Close as CloseIcon } from 'images'

const Close = ({ id, containerProps, ...rest }) => {
  return (
    <Flex
      as='a'
      className='unstyled'
      id={id}
      aria-label='Close'
      {...containerProps}
    >
      <ResponsiveSVG
        SVG={CloseIcon}
        display='flex'
        alignItems='center'
        width='25px'
        height='25px'
        p='6px'
        fill='primary'
        aria-hidden='true'
        {...rest}
      />
    </Flex>
  )
}

Close.propTypes = {
  id: PropTypes.string,
  containerProps: PropTypes.object
}

Close.defaultProps = {
  containerProps: {}
}

export default Close
