import React from 'react'
import PropTypes from 'prop-types'

import Flex from './Flex'

const Container = ({ children, centerProps, ...rest }) => {
  return (
    <Flex
      justifyContent='center'
      mx={[null, null, 'auto']}
      px={['15px', null, '50px']}
      {...rest}
    >
      <Flex
        width='100%'
        maxWidth={'1320px'}
        flexDirection='column'
        {...centerProps}
      >
        {children}
      </Flex>
    </Flex>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired
}

export default Container
