import styled from 'styled-components'
import { space } from 'styled-system'

const FieldSet = styled.fieldset`
  ${space}
  padding: 0;
  border: none;
`

FieldSet.defaultProps = {
  mb: '12px',
  mt: '0',
  mx: '0'
}

export default FieldSet
