
import styled from 'styled-components'
import { border } from 'styled-system'

import { Box } from '../../elements'
import { componentHeights as heights } from '../../themes'

const Container = styled(Box)(
  border
)

Container.defaultProps = {
  bg: 'base',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'primary',
  left: ['5%', '15%', null, '20%'],
  maxHeight: [heights.modalMax.mobile, null, heights.modalMax.desktop],
  position: 'fixed',
  top: '10%',
  width: ['90%', '70%', null, '60%'],
  zIndex: '1'
}

export default Container
