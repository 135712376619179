import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

import { Metadata } from '../../typography/Metadata'
import Flex from '../Flex'
import ResponsiveSVG from '../ResponsiveSVG'

import { Success, SuccessRest, Warning } from 'images'

const FieldFeedback = ({ type, children, ...rest }) => {
  const { colors } = useTheme()
  let Icon, color

  switch (type) {
    case 'rest':
      Icon = SuccessRest
      color = colors.grey6
      break
    case 'success':
      Icon = Success
      color = colors.grey6
      break
    case 'error':
      Icon = Warning
      color = colors.error
      break
  }

  return (
    <Flex mt='4px' alignItems='center' {...rest}>
      <ResponsiveSVG SVG={Icon}
        mr='8px'
        height='15px'
        width='15px'
        minHeight='15px'
        minWidth='15px'
        fill='primary'
        stroke='base'
      />
      <Metadata color={color}>{children}</Metadata>
    </Flex>
  )
}

FieldFeedback.defaultProps = {
  type: 'rest'
}

FieldFeedback.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node
}

export default FieldFeedback
