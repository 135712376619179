import styled from 'styled-components'
import { P as UnstyledP, Header as UnstyledHeader } from 'ui/acro/typography'

const P = styled(UnstyledP)`
  margin-bottom: 15px;
`

const Header = styled(UnstyledHeader)`
  padding-top: 20px;
  padding-bottom: 10px;
`

const PrivacyOL = styled('ol')`
  padding: 0px 0px 0px 10px !important;
`

const PrivacyUL = styled('ul')`
  margin-block-start: 5px !important;
  padding-inline-start: 10px !important;
  list-style-type: disc !important;
`

const PrivacyLink = styled('a')`
  text-decoration: underline;
`

Header.defaultProps = {
  variant: 'semibold'
}

export { P, Header, PrivacyOL, PrivacyUL, PrivacyLink }
