import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from '../elements'
import { Caption, P, Title } from '../typography'
import styled from 'styled-components'

const FlexContainer = styled(Flex)`
  text-align: start;
`

const StepHeader = ({ body, step, title, ...rest }) => (
  <FlexContainer>
    {step && (
      <Caption
        id='stepHeader_caption'
        variant='bold'
        color='grey5'
        pb={['8px', null, '12px']}
      >
        {step}
      </Caption>
    )}
    {title && (
      <Title id='stepHeader_title' pb={['9px', null, '6px']}>
        {title}
      </Title>
    )}
    {body && <P id='stepHeader_body'>{body}</P>}
  </FlexContainer>
)

StepHeader.propTypes = {
  body: PropTypes.node,
  step: PropTypes.node,
  title: PropTypes.node
}

export default StepHeader
