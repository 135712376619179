import React from 'react'
import PropTypes from 'prop-types'
import Responsive from './Responsive'

const MobileOnly = ({ children, ...rest }) => (
  <Responsive show={[true, false, false]} {...rest}>
    {children}
  </Responsive>
)

MobileOnly.propTypes = {
  children: PropTypes.node
}

export default MobileOnly
