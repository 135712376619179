import styled from 'styled-components'
import { P } from 'ui/acro/typography/P'

const Label = styled(P)``

Label.defaultProps = {
  as: 'label',
  color: 'grey6',
  variant: 'semibold',
  width: '100%'
}

export default Label
