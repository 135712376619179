import React from 'react'
import PropTypes from 'prop-types'
import Box from './Box'

const Responsive = ({ show, display = 'inherit', children, ...rest }) => {
  const formattedDisplay = show.map(val => val ? display : 'none')

  return (
    <Box display={formattedDisplay} {...rest}>
      {children}
    </Box>
  )
}

Responsive.propTypes = {
  show: PropTypes.array.isRequired,
  display: PropTypes.string,
  children: PropTypes.node
}

export default Responsive
