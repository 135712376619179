import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from '../elements'
import { Headline, Header } from '../typography'

const InfoSection = ({ children, headline, body, center, top, ...rest }) => {
  const alignItems = center ? 'center' : 'start'

  return (
    <Flex
      alignItems={['center', null, alignItems]}
      flexDirection='column'
      justifyContent='center'
      textAlign={['center', null, center ? 'center' : 'left']}
      {...rest}
    >
      {headline && <Headline>{headline}</Headline>}
      {body && <Header
        pt={['7px', null, '15px']}
        pb={children && ['23px', null, '37px']}
        color='grey6'
        css='white-space: pre-line'
      >
        {body}
      </Header>
      }

      {children}

    </Flex>
  )
}

InfoSection.propTypes = {
  align: PropTypes.string,
  children: PropTypes.any,
  headline: PropTypes.string,
  offset: PropTypes.bool,
  body: PropTypes.string,
  left: PropTypes.bool,
  right: PropTypes.bool,
  center: PropTypes.bool,
  top: PropTypes.bool
}

export default InfoSection
