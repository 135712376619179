import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useOutsideClick } from 'hooks'
import { Box, Flex, HR } from '../elements'

const DropdownHeader = styled(Box)`
  cursor: pointer;
`

const DropdownMenu = styled(Box)(props => ({
  background: props.theme.colors.base,
  borderRadius: '4px',
  borderLeft: `1px solid ${props.theme.colors.grey4}`,
  borderRight: `1px solid ${props.theme.colors.grey4}`,
  borderBottom: `1px solid ${props.theme.colors.grey4}`,
  boxShadow: `0px 2px rgba(${props.theme.colors.rgb.primary}, 0.24), 0px 0px 2px rgba(${props.theme.colors.rgb.primary}, 0.12)`,
  maxHeight: '275px',
  overflow: 'auto',
  position: 'absolute',
  width: '100%',
  zIndex: '10'
}))

const DropdownItem = styled(Flex)(props => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '50px',
  paddingLeft: '16px',
  paddingRight: '16px',
  width: '100%',
  cursor: 'pointer',
  backgroundColor: (props.selected ? props.theme.colors.grey1 : ''),
  '&:hover': {
    backgroundColor: props.theme.colors.grey1
  }
}))

const index = ({ onChange, options, renderInitialMenuItem, renderMenuItem, renderHeader, selectedValue }) => {
  const dropdownRef = useRef()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = choice => {
    if (selectedValue !== choice) {
      // onChange() is a callback provided when rendering the <Dropdown/>.
      // This implementation is therefore agnostic as to what happens on this change.
      onChange(choice)
    }
    handleClose()
  }

  useOutsideClick(dropdownRef, handleClose, open)

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }}>
      <DropdownHeader
        onClick={() => setOpen(!open)}
        minWidth='187px'
      >
        {renderHeader(selectedValue, open)}
        <HR />
      </DropdownHeader>
      {open && (
        <DropdownMenu>
          {renderInitialMenuItem && (
            <DropdownItem
              key={'initial-option'}
              onClick={() => handleChange(null)}
              selected={selectedValue === {}}
            >
              {renderInitialMenuItem()}
            </DropdownItem>
          )}
          {options.map((option, index) => {
            const optSelected = option === selectedValue
            return (
              <DropdownItem
                key={index}
                onClick={() => handleChange(option)}
                selected={optSelected}
              >
                {renderMenuItem(option, optSelected)}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      )}
    </div>
  )
}

index.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  renderInitialMenuItem: PropTypes.func,
  renderMenuItem: PropTypes.func,
  renderHeader: PropTypes.func,
  selectedValue: PropTypes.object
}

export default index
