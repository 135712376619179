import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field as FormikField } from 'formik'
import Collapse from 'ui/acro/animations/Collapse'
import FieldSet from 'ui/acro/elements/forms/FieldSet'
import FieldFeedback from 'ui/acro/elements/forms/FieldFeedback'
import FieldError from 'ui/acro/elements/forms/FieldError'
import Input from 'ui/acro/elements/forms/Input'
import Label from 'ui/acro/elements/forms/Label'
import { Box } from 'ui/bend'
import { ResponsiveSVG } from '../elements'
import { Hide, Show } from 'images/latest'
import {
  lowercaseCharacterRegex,
  numberCharacterRegex,
  uppercaseCharacterRegex
} from 'components_v2/Subscribe/utils'

const PasswordInput = ({
  labelTitle, // labelTitle is used to display the text above the input, ex: 'Password', 'New Password', 'Confirm Password'
  name,
  showValidations,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  placeHolder
}) => {
  // We use separate focus states to only show the error state for password length on unfocus.
  const [passwordFocus, setPasswordFocus] = useState(false)
  const [passwordConfirmationFocus, setPasswordConfirmationFocus] =
    useState(false)
  const [passwordType, setPasswordType] = useState('password')

  // This component now supports two types of password inputs - one for a regular password validation, and an additional 'confirm password' input for pages where we ask the user to retype their password. When we rewrite the settings pages to React, we should use both of these inputs like we do on ChooseNewPassword.js. To switch between the two, use prop name='password' for regular PW input, and name='password_confirmation' for the confirmation input.
  const passwordInput = name === 'password'
  const confirmPasswordInput = name === 'password_confirmation'

  // hide/show icon to toggle password visibility
  const togglePasswordType = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  const passwordValidations = (pw, error) => {
    if (passwordInput && showValidations) {
      return (
        <>
          <FieldFeedback type={pw.length >= 8 ? 'success' : 'rest'}>
            8 or more characters
          </FieldFeedback>
          <FieldFeedback
            type={
              lowercaseCharacterRegex.test(pw) === true ? 'success' : 'rest'
            }
          >
            At least 1 lowercase letter
          </FieldFeedback>
          <FieldFeedback
            type={
              uppercaseCharacterRegex.test(pw) === true ? 'success' : 'rest'
            }
          >
            At least 1 uppercase letter
          </FieldFeedback>
          <FieldFeedback
            type={numberCharacterRegex.test(pw) === true ? 'success' : 'rest'}
          >
            At least 1 number
          </FieldFeedback>
        </>
      )
    } else if (confirmPasswordInput && showValidations) {
      if (values.password_confirmation.length >= 1) {
        return (
          <FieldFeedback
            type={error === 'Passwords do not match' ? 'rest' : 'success'}
          >
            Passwords need to match
          </FieldFeedback>
        )
      }
    }
  }

  if (passwordInput) {
    return (
      <FieldSet>
        <Label position='relative'>
          {labelTitle}
          <Input
            as={FormikField}
            type={passwordType}
            name={name}
            autoComplete='new-password'
            onKeyUp={handleChange}
            className={
              touched.password &&
              errors.password &&
              (values.password === '' || !passwordFocus) &&
              'error'
            }
            onFocus={() => {
              setPasswordFocus(!passwordFocus)
            }}
            onBlur={(e) => {
              setPasswordFocus(!passwordFocus)
              handleBlur(e)
            }}
            placeHolder={placeHolder}
          />
          <Box
            position='absolute'
            top={!labelTitle ? '2px' : '46px'}
            right='4%'
            cursor='pointer'
            zIndex='1'
            onClick={togglePasswordType}
          >
            <ResponsiveSVG
              SVG={passwordType === 'password' ? Show : Hide}
              width='20px'
              height='20px'
            />
          </Box>
        </Label>
        <Collapse isOpened={passwordFocus}>
          {passwordValidations(values.password, errors.password)}
        </Collapse>
        <Collapse
          isOpened={touched.password && errors.password && !passwordFocus}
        >
          {touched.password && errors.password && (
            <FieldError>{errors.password}</FieldError>
          )}
        </Collapse>
      </FieldSet>
    )
  } else if (confirmPasswordInput) {
    return (
      <FieldSet>
        <Label position='relative'>
          {labelTitle}
          <Input
            as={FormikField}
            type={passwordType}
            name={name}
            className={
              touched.password_confirmation &&
              errors.password_confirmation &&
              (values.password_confirmation === '' ||
                !passwordConfirmationFocus) &&
              'error'
            }
            onFocus={() => {
              setPasswordConfirmationFocus(!passwordConfirmationFocus)
            }}
            onBlur={(e) => {
              setPasswordConfirmationFocus(!passwordConfirmationFocus)
              handleBlur(e)
            }}
          />
          <Box
            position='absolute'
            top='46px'
            right='4%'
            cursor='pointer'
            zIndex='1'
            onClick={togglePasswordType}
          >
            <ResponsiveSVG
              SVG={passwordType === 'password' ? Show : Hide}
              width='20px'
              height='20px'
            />
          </Box>
        </Label>
        <Collapse isOpened={passwordConfirmationFocus}>
          {passwordValidations(
            values.password_confirmation,
            errors.password_confirmation
          )}
        </Collapse>
        <Collapse
          isOpened={
            touched.password_confirmation &&
            errors.password_confirmation &&
            !passwordConfirmationFocus
          }
        >
          {touched.password_confirmation && errors.password_confirmation && (
            <FieldError>{errors.password_confirmation}</FieldError>
          )}
        </Collapse>
      </FieldSet>
    )
  }
}

PasswordInput.propTypes = {
  labelTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showValidations: PropTypes.bool.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func
}

export default PasswordInput
