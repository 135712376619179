import styled from 'styled-components'
import { alignItems, alignContent, color, grid, layout, justifyItems, justifyContent, space } from 'styled-system'

/************************************************************
/*  Give <Grid> an array of strings to define column layouts and support responsive design.
/*  For Example:
/*    const mainGrid = [
/*      `"Nav Nav Nav"
/*      "PlanEntry PlanEntry PlanEntry"
/*      "Footer Footer Footer"`,
/*      `"Nav Nav Nav"
/*      ". PlanEntry ."
/*      "Footer Footer Footer"`,
/*      `"Nav Nav Nav"
/*      ". PlanEntry ."
/*      "Footer Footer Footer"`
/*    ]
/*
/*  const mainGridCols = [
/*    `auto`,
/*    `15% auto 15%`,
/*    `15% auto 15%`
/*  ]
/*    <Grid
/*        gridTemplateArea={mainGrid}
/*        gridTemplateCols={mainGridCols}
/*    />
/************************************************************
*/

const Grid = styled.div`
  ${alignItems}
  ${alignContent}
  ${color}
  ${grid}
  ${layout}
  ${justifyItems}
  ${justifyContent}
  ${space}
  grid-area: ${props => props.area || ''}
`

Grid.defaultProps = {
  display: 'grid'
}

export default Grid
