import React from 'react'
import PropTypes from 'prop-types'

import ButtonBase from './ButtonBase'
import { ResponsiveSVG } from 'ui/acro/elements'
import { RightChevron } from 'images'

const NextButton = ({ handleClick, fill = 'primary', ...props }) => (
  <ButtonBase {...props} onClick={handleClick} onKeyPress={handleClick} tabIndex='0' aria-label='carousel-next-button'>
    <ResponsiveSVG height='100%' width='100%' fill={fill} SVG={RightChevron} />
  </ButtonBase>
)

NextButton.defaultProps = {
  top: '40%',
  right: '-50px'
}

NextButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default NextButton
