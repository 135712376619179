import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import Image from './Image'

// This component provides responsive image utility.
// It takes an array of image sources and their breakpoints, then sets a responsive <picture /> element using this array.
// src is used to define the smallest, fall-back image served.
const ResponsiveImage = ({ src, imagesArray, height, offset = 150, ...rest }) => {
  return (
    <LazyLoad height={height} offset={offset}>
      <picture>
        {imagesArray && imagesArray.map(image => (
          <source key={image.src} srcSet={image.src} media={`(min-width: ${image.breakpoint}px)`} />
        ))}
        <Image src={src} height={height} {...rest} />
      </picture>
    </LazyLoad>
  )
}

ResponsiveImage.propTypes = {
  src: PropTypes.string,
  imagesArray: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ResponsiveImage
