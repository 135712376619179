import React from 'react'
import styled from 'styled-components'
import { space, position, color } from 'styled-system'

// The default sticky height is set to our new React navbar (['60px', null, '80px']).
// To use ProgressBar in legacy components, we will need to supply a different top.

const ProgressBarStyled = styled.div`
  ${position}
  ${space}
  
  position: sticky;
  z-index: 10;
  background: ${(props) => props.theme.colors.grey1};
  width: 100%;
  height: ${(props) => props.theme.componentHeights.progressBar};

  & > span {
    ${color}
    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-top-right-radius: ${(props) =>
      props.fillPercent !== '100' ? '4px' : ''};
    border-bottom-right-radius: ${(props) =>
      props.fillPercent !== '100' ? '4px' : ''};
    width: ${(props) => (props.fillPercent ? props.fillPercent + '%' : '0%')};
    transition: width 0.25s ease;
  }

  ${(props) => props.theme.mediaQueries.medium} {
    & > span {
      transition-duration: 0.5s;
    }
  }
`

const ProgressBar = (props) => (
  <ProgressBarStyled bg={props.color || 'primary'} {...props}>
    <span></span>
  </ProgressBarStyled>
)

ProgressBar.defaultProps = {
  top: ['60px', null, '80px']
}

export default ProgressBar
