import styled from 'styled-components'
import { background, border, flexbox, size, layout } from 'styled-system'

import Box from './Box'

// Our flex element will have access to grid-area so it can seamlessly integrate into grid layouts

const Flex = styled(Box)`
  ${background}
  ${flexbox}
  ${layout}
  ${size}
  ${border}
  grid-area: ${(props) => props.area || ''};
`

Flex.defaultProps = {
  display: 'flex'
}

export default Flex
