import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { IntensityOne, IntensityTwo, IntensityThree, IntensityFour } from 'images'

import ResponsiveSVG from './ResponsiveSVG'

const icons = [null, IntensityOne, IntensityTwo, IntensityThree, IntensityFour]

const IntensityIcon = ({ intensity }) => {
  const icon = icons[intensity] ? icons[intensity] : () => null

  return (
    <Fragment>
      <ResponsiveSVG height='16px' width='16px' fill='primary' as='span' SVG={icon} /> <span>Intensity {intensity}</span>
    </Fragment>
  )
}

IntensityIcon.propTypes = {
  intensity: PropTypes.number.isRequired
}

export default IntensityIcon
