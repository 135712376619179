import React from 'react'
import PropTypes from 'prop-types'

import { Header } from '../../typography'

const BannerBody = ({ children }) => (
  <Header
    variant='regular'
    color='white'
    pb={['20px', null, '0px']}
    textAlign={['center', null, 'left']}
  >
    {children}
  </Header>
)

BannerBody.propTypes = {
  children: PropTypes.node
}

export default BannerBody
