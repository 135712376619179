import React from 'react'
import classNames from 'classnames'
import './ReadMoreLegacy.css.scss'
import PropTypes from 'prop-types'

import { Caption } from 'ui/acro/typography/Caption'

class ReadMore extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      readmoreNeeded: false
    }

    this.readMoreChildren = React.createRef()
    this.renderToggleButton = this.renderToggleButton.bind(this)
  }

  componentDidMount() {
    if (this.readMoreChildren.current.clientHeight > this.props.initialHeight) {
      this.setState({ readmoreNeeded: true })
    }
  }

  renderToggleButton() {
    if (this.state.readmoreNeeded) {
      return (
        <Caption
          variant='bold'
          as='a'
          className='toggle'
          onClick={this.toggleReadMore.bind(this)}
          id={this.props.id}
        >
          {this.state.open ? 'Read Less' : 'Read More'}
        </Caption>
      )
    } else {
      return (
        <a
          className='ReadmoreLayout__no-readmore-compensator'
          style={{
            height: `${this.props.noReadMoreHeightCompensator}px`,
            display: 'block',
            width: '100%'
          }}
        />
      )
    }
  }

  toggleReadMore(e) {
    e.preventDefault()

    if (!this.state.openHeight) {
      this.setState({ openHeight: this.readMoreChildren.current.clientHeight })
    }

    this.setState(prevState => {
      return { open: !prevState.open }
    })
  }

  render() {
    this.readMoreContentClass = classNames(
      'read-more-content',
      'ReadmoreLayout__content',
      {
        'ReadmoreLayout--open': (this.state.open || !this.state.readmoreNeeded)
      }
    )

    const currentHeight = this.state.open
      ? this.state.openHeight
      : this.props.initialHeight

    const minHeight = this.props.setMinHeight
      ? this.props.initialHeight
      : 0

    return (
      <div className='ReadmoreLayout'>
        <div
          className={this.readMoreContentClass}
          style={{
            maxHeight: `${currentHeight}px`,
            minHeight: `${minHeight}px`
          }}
        >
          <div ref={this.readMoreChildren}>{this.props.children}</div>
        </div>
        {this.renderToggleButton()}
      </div>
    )
  }
}

ReadMore.defaultProps = {
  noReadMoreHeightCompensator: 0,
  setMinHeight: true
}

ReadMore.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  initialHeight: PropTypes.number,
  setMinHeight: PropTypes.bool,
  noReadMoreHeightCompensator: PropTypes.number
}

export default ReadMore
