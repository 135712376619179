import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Grid } from '../elements'
import { containerCols } from '../constants'

const MainContainer = ({ children, gridColumnGap, gridTemplateAreas, gridTemplateColumns, gridTemplateRows }) => (
  <Flex
    justifyContent='center'
    mx={[null, null, 'auto']}
  >
    <Grid
      gridColumnGap={gridColumnGap}
      gridTemplateAreas={gridTemplateAreas}
      gridTemplateColumns={containerCols || gridTemplateColumns}
      gridTemplateRows={gridTemplateRows}
      maxWidth='1320px' // This number is set by design spec.
      mx={[null, null, '50px']} // This lets us set an effective min-gutter width
    >
      {children}
    </Grid>
  </Flex>
)

MainContainer.propTypes = {
  children: PropTypes.node,
  gridColumnGap: PropTypes.array,
  gridTemplateAreas: PropTypes.array,
  gridTemplateAreas: PropTypes.array,
  gridTemplateRows: PropTypes.array
}

export default MainContainer
