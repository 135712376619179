import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Caption } from 'ui/acro/typography/Caption'

const RadioLabel = styled(Caption)(props => ({
  border: (props.card && `1px solid ${props.theme.colors.grey3}`),
  width: (props.card && '100%'),
  borderRadius: (props.card && '4px'),
  display: (props.card && 'flex'),
  alignItems: (props.card && 'center'),
  padding: (props.card && '25px 17px'),
  cursor: 'pointer',

  '[type="radio"]': {
    opacity: 0,
    position: 'absolute'
  },

  '[type="radio"] + div': {
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },

  '[type="radio"] + div:before': {
    content: "''",
    background: props.theme.colors.base,
    border: `1px solid ${props.theme.colors.primary}`,
    borderRadius: '100%',
    height: '20px',
    marginRight: '18px',
    width: '20px'
  },

  '[type="radio"]:checked + div:before': {
    transition: 'background-color .15s ease-in-out',
    backgroundColor: props.theme.colors.primary,
    boxShadow: `inset 0 0 0 5px ${props.theme.colors.base}`
  }
}))

const Radio = ({ name, selected, handleChange, children, ...rest }) => {
  return (
    <RadioLabel variant='heavy' color='grey6' htmlFor={name} {...rest}>
      <input type='radio' name={name} id={name} onChange={() => handleChange(name)} checked={selected === name} />
      <div>{children}</div>
    </RadioLabel>
  )
}

RadioLabel.defaultProps = {
  as: 'label'
}

// card: bool
Radio.propTypes = {
  name: PropTypes.string,
  selected: PropTypes.string,
  handleChange: PropTypes.func,
  children: PropTypes.node,
}

export default Radio
