import React from 'react'
import PropTypes from 'prop-types'

import Box from './Box'

export const MAX_WIDTH = '535px' // This number is set by the design spec

const ContentBox = ({ children, ...rest }) => {
  return (
    <Box
      width='100%'
      maxWidth={MAX_WIDTH}
      px={['38px', null, '0px']} // This lets us set an effective min-gutter width
      {...rest}
    >
      {children}
    </Box>
  )
}

ContentBox.propTypes = {
  children: PropTypes.node
}

export default ContentBox
