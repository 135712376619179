import styled from 'styled-components'
import { variant } from 'styled-system'

import Text from './Text'

// Proxima Nova font weight values only include 900, 700, 600, and 400.
// We will use regular: 400 for regular, heavy: 600 for semibold, and black: 700 for bold.

const defaultValues = {
  as: 'h3',
  fontSize: ['18px', '22px'],
  fontFamily: 'Proxima-Nova',
  letterSpacing: 0,
  lineHeight: '170%',
  fontWeight: 'regular',
  color: 'grey6',
  textTransform: 'none'
}

// I inlined the lineHeight changes here because they are the only place we use 125%, and I'm not sure how ubiquitous they will be.
const Header = styled(Text)(
  variant({
    variants: {
      semibold: {
        lineHeight: '125%',
        fontWeight: 'heavy'
      },
      bold: {
        lineHeight: '125%',
        fontWeight: 'black'
      }
    }
  })
)

Header.defaultProps = {
  ...defaultValues
}

export { defaultValues, Header }
