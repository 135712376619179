import React from 'react'
import PropTypes from 'prop-types'

import { Title } from '../../typography'

const BannerTitle = ({ children }) => (
  <Title
    textAlign={['center', null, 'left']}
    maxWidth={['270px', '100%']}
    mb={['9px', null, null]}
    color='white'
  >
    {children}
  </Title>
)

BannerTitle.propTypes = {
  children: PropTypes.node
}

export default BannerTitle
