// We can't simply import new Button and Spinner elements, since they rely on the new Themes.

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { layout, flexbox, space, variant, system } from 'styled-system'

import Spinner from 'ui/acro/animations/Spinner'
import { defaultValues as ctaValues } from 'ui/acro/typography/CTA'

const tertiaryValues = {
  ...ctaValues,
  fontSize: ['14px', '16px'],
  minWidth: ['146px', '160px']
}

const StyledButton = styled('button')(
  {
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: '160px',
    minHeight: '50px',
    transition: '.15s ease-in-out'
  },
  system({
    whiteSpace: true
  }),
  layout,
  flexbox,
  space,
  variant({
    variants: {
      primary: {
        ...ctaValues,
        bg: 'primary',
        color: 'base',
        border: 'none',
        '&:hover': {
          bg: ['primary', 'grey7'],
          color: 'base'
        },
        '&:focus': {
          color: 'base'
        },
        '&:active': {
          color: 'base'
        },
        '&:disabled:not(.processing)': {
          bg: 'grey1',
          color: 'grey4'
        },
        '> i': {
          color: 'base',
          '&:hover': {
            color: 'base'
          },
          '&:disabled:not(.processing)': {
            color: 'grey4'
          }
        }
      },
      secondary: {
        ...ctaValues,
        bg: 'base',
        borderColor: 'primary',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: 'primary',
        '&:hover': {
          color: [null, 'base'],
          borderColor: [null, 'grey7'],
          bg: [null, 'grey7']
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: [null, 'base']
        },
        '&:disabled:not(.processing)': {
          bg: 'base',
          borderColor: 'grey4',
          color: 'grey4'
        },
        '> i': {
          color: 'primary',
          '&:hover': {
            color: 'base'
          },
          '&:disabled:not(.processing)': {
            color: 'grey4'
          }
        }
      },
      tertiary: {
        ...tertiaryValues,
        bg: 'base',
        borderColor: 'grey3',
        borderStyle: 'solid',
        borderWidth: '1px',
        color: 'primary',
        '&:hover': {
          borderColor: [null, 'primary']
        },
        '&:focus': {
          color: 'primary'
        },
        '&:active': {
          color: [null, 'primary']
        },
        '&:disabled:not(.processing)': {
          borderColor: 'grey4',
          color: 'grey4'
        },
        '> i': {
          color: 'primary',
          '&:hover': {
            color: 'primary'
          },
          '&:disabled:not(.processing)': {
            color: 'grey4'
          }
        }
      },
      // primary-inverted does not change with theme.
      'primary-inverted': {
        ...ctaValues,
        bg: 'white',
        color: 'black',
        borderColor: 'white',
        borderStyle: 'solid',
        borderWidth: '1px',
        '&:hover': {
          bg: [null, 'charcoal'],
          borderColor: [null, 'charcoal'],
          color: [null, 'white']
        },
        '&:focus': {
          color: 'black'
        },
        '&:active': {
          color: [null, 'white']
        },
        '&:disabled:not(.processing)': {
          bg: 'white',
          color: 'dawn'
        },
        '> i': {
          color: 'black',
          '&:hover': {
            color: 'white'
          },
          '&:disabled:not(.processing)': {
            color: 'dawn'
          }
        }
      },
      // secondary-inverted does not change with theme.
      'secondary-inverted': {
        ...ctaValues,
        bg: 'transparent',
        color: 'white',
        borderColor: 'white',
        borderStyle: 'solid',
        borderWidth: '1px',
        '&:hover': {
          bg: [null, 'white'],
          borderColor: [null, 'white'],
          color: [null, 'black']
        },
        '&:focus': {
          color: 'white'
        },
        '&:active': {
          color: [null, 'black']
        },
        '&:disabled:not(.processing)': {
          color: 'dawn',
          borderColor: 'dawn'
        },
        '> i': {
          color: 'white',
          '&:hover': {
            color: 'black'
          },
          '&:disabled:not(.processing)': {
            color: 'dawn'
          }
        }
      }
    }
  })
)

const Button = ({
  children,
  disabled,
  processing,
  link,
  onClick = null,
  variant,
  ...props
}) => {
  return (
    <StyledButton
      disabled={disabled || processing}
      className={processing && 'processing'}
      variant={variant || 'primary'}
      px={'30px'}
      onClick={onClick}
      {...props}
    >
      {processing ? <Spinner /> : children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  link: PropTypes.string,
  processing: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Button
