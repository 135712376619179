import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Box from 'ui/acro/elements/Box'
import Responsive from 'ui/acro/elements/Responsive'
import DesktopOnly from 'ui/acro/elements/DesktopOnly'

import Modal from '../Modal'

import CloseIcon from './CloseIcon'
import Container from './Container'
import Header from './Header'

// This component renders a modal containing a Close Button (TBD) and any passed through children components.
// It leverages the Modal (which sets a portal and overlay) and appends useOutsideClick() and other styling.
// We set ref on the content Container so we can catch outside clicks by ref.

const ContentModal = ({ useModal, header, children, ...rest }) => {
  return (
    <Modal {...useModal}>
      <Container ref={useModal.ref} {...rest}>
        <Box position='relative'>
          {header ? (
            <Header header={header} close={useModal.close} />
          ) : (
            <Fragment>
              <Responsive show={[true, true, false]}>
                <CloseIcon onClose={useModal.close} absolute={true} />
              </Responsive>

              <DesktopOnly>
                <CloseIcon onClose={useModal.close} absolute={true} />
              </DesktopOnly>
            </Fragment>
          )}

          {children}
        </Box>
      </Container>
    </Modal>
  )
}

ContentModal.propTypes = {
  useModal: PropTypes.object,
  header: PropTypes.node,
  children: PropTypes.node
}

export default ContentModal
