import React from 'react'
import PropTypes from 'prop-types'

import FieldFeedback from './FieldFeedback'

const FieldError = ({ children, ...rest }) => (
  <FieldFeedback type='error' {...rest}>{children}</FieldFeedback>
)

FieldError.propTypes = {
  children: PropTypes.node
}

export default FieldError
