import styled from 'styled-components'
import { variant } from 'styled-system'

import Text from './Text'

const defaultValues = {
  fontSize: ['14px', '16px'],
  fontFamily: 'Arquitecta',
  letterSpacing: 3,
  lineHeight: '105%',
  textTransform: 'uppercase'
}

const Caption = styled(Text)(
  variant({
    variants: {
      bold: {
        fontWeight: 'bold'
      },
      heavy: {
        fontWeight: 'heavy'
      },
      medium: {
        fontWeight: '400'
      }
    }
  })
)

Caption.defaultProps = {
  as: 'h4',
  variant: 'bold',
  ...defaultValues
}

export { Caption, defaultValues }
