import styled from 'styled-components'
import { space, layout } from 'styled-system'

import { DownChevron } from 'images'

const ChevronBase = styled(DownChevron)`
  ${layout}
  ${space}
  fill: ${props => props.theme.colors.primary};
  transition: transform 0.25s ease-in-out, fill 0.25s ease-in-out;
  transform: ${props => props.up && 'rotate(180deg)'};
  &.flip {
    transform: ${props => props.up ? 'rotate(0deg)' : 'rotate(180deg)'};
  }
`

export default ChevronBase