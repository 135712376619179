import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import {
  Introductory,
  Beginner,
  Moderate,
  Intermediate,
  Advanced
} from 'images'

import ResponsiveSVG from './ResponsiveSVG'

const icons = {
  introductory: Introductory,
  beginner: Beginner,
  moderate: Moderate,
  intermediate: Intermediate,
  advanced: Advanced
}

const DifficultyIcon = ({ difficulty }) => {
  const icon = icons[difficulty] ? icons[difficulty] : () => null

  return (
    <Fragment>
      <ResponsiveSVG
        height='16px'
        width='16px'
        fill='primary'
        as='span'
        SVG={icon}
      />{' '}
      <span>{difficulty}</span>
    </Fragment>
  )
}

DifficultyIcon.propTypes = {
  difficulty: PropTypes.string.isRequired
}

export default DifficultyIcon
