import styled from 'styled-components'
import { css } from '@styled-system/css'

import { P } from 'ui/acro/typography/P'

const DurationBadge = styled(P)(props => (
  css({
    bg: `rgba(0, 0, 0, 0.75)`,
    borderRadius: '4px',
    color: props.theme.colors.white,
    wordBreak: 'none',
    position: 'absolute'
  })
))

export default DurationBadge