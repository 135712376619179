import React from 'react'
import PropTypes from 'prop-types'

import ButtonBase from './ButtonBase'
import { ResponsiveSVG } from 'ui/acro/elements'
import { LeftChevron } from 'images'

const PrevButton = ({ handleClick, fill = 'primary', ...props }) => (
  <ButtonBase {...props} onClick={handleClick} onKeyPress={handleClick} tabIndex='0' aria-label='carousel-next-button'>
    <ResponsiveSVG height='100%' width='100%' fill={fill} SVG={LeftChevron} />
  </ButtonBase>
)

PrevButton.defaultProps = {
  top: '40%',
  left: '-50px'
}

PrevButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default PrevButton
