import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box } from '../elements'

const StyledBox = styled(Box)`
  cursor: pointer;
  border: 2px solid ${props => props.selected ? props.theme.colors.primary : props.theme.colors.grey3};
  border-radius: 4px;
  transition: all .15s linear;
  box-shadow: ${props => props.selected && '1px 1px 10px rgba(0, 0, 0, 0.25)'};

  /* Don't show box-shadow hover effect on mobile */
  ${props => props.theme.mediaQueries.small} {
    &:hover {
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
    }
  }
`

const OptionCard = ({ children, onSelect, selected, ...rest }) => {
  return (
    <StyledBox
      position='relative'
      px={['24px', null, '32px']}
      py={['16px', null, '23px']}
      width='100%'
      selected={selected}
      onClick={onSelect}
      {...rest}
    >
      {children}
    </StyledBox>
  )
}

OptionCard.propTypes = {
  children: PropTypes.node,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
}

export default OptionCard
