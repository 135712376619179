import React, { Fragment } from 'react'

import { P, Header } from './DisclaimerStyles'

export default () => (
  <Fragment>
    <P>
      These Terms of Service (“Terms”) are a legal agreement between you and
      Alo, LLC and its affiliates, subsidiaries and successors and assigns
      (collectively, (“Alo” “us,” or “we”) regarding your use of services
      available at www.alomoves.com and via any Alo Moves mobile or other
      software application, including any downloadable software program that you
      are accessing or installing on your device or personal computer and the
      services available through that software program (together, the
      “Service”). As used in these Terms, the terms "you" and "your" refer to,
      and these Terms shall be binding upon, any person or entity who purchases,
      activates, receives, uses, accepts or otherwise accesses the Service.
    </P>

    <P>
      BY USING ANY PORTION OF THE SERVICE, YOU ARE AGREEING TO BE BOUND BY AND
      ARE BECOMING A PARTY TO THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS,
      DO NOT USE THE SERVICE.
    </P>

    <Header>Service Description</Header>

    <P>
      The Service is a fitness, wellness and social network and video platform.
      Users of the Service may log their fitness activities; see, comment and
      like the activities of other users; and receive analyses and insights from
      the Service about their progress. Users may also download training
      materials from other users and coaches, some of which is provided for a
      fee.
    </P>

    <Header>Eligibility</Header>

    <P>
      You must be at least 18 years of age or older, or the age of majority as
      determined by the laws of your province or territory of residency, and
      have full legal capacity to assume the obligations set forth in these
      Terms and to use the Service. The Service is not intended for those under
      the age of 18. You represent and warrant that any profile information you
      submit is true and accurate and that you are of the age of majority as
      determined by the laws of your province or territory of residency, and are
      fully able and competent to enter into and abide by these Terms. If you
      are using the Service on behalf of your employer, you represent that you
      are authorized to accept these Terms on your employer’s behalf. Use of the
      Service is void where prohibited.
    </P>

    <Header>Alo Moves Account</Header>

    <P>
      In order to use the Service, you must create an account (an “Alo Moves
      Account”). When registering for an Alo Moves Account, you must provide
      accurate and complete information and promptly update this information to
      keep it current. If you provide any information that is inaccurate or
      incomplete, or we have reason to believe that the information is
      inaccurate or incomplete, we may suspend or terminate your Alo Moves
      Account and your use of the Service, and provide you with partial refunds,
      as applicable, for any unused portion of Fees you may have paid for a Paid
      Subscription (as defined below), if any, as set forth below.
    </P>

    <P>
      You are solely responsible for all activities that occur through your Alo
      Moves Account – with or without your permission. To protect your Alo Moves
      Account from unauthorized use, do not provide your username or password to
      anyone else. Please notify us immediately of any unauthorized use of your
      Alo Moves Account or any other breach of security by sending an email to
      info@alomoves.com. TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, WE ARE
      NOT RESPONSIBLE FOR ANY LOSS OR ACTIVITY THAT RESULTS FROM THE
      UNAUTHORIZED USE OF YOUR ALO MOVES ACCOUNT. Any personal information you
      provide is governed by the terms of the Alo Moves Privacy Policy (the
      “Privacy Policy”), which is located under the “privacy” heading at
      https://www.alomoves.com/.
    </P>

    <Header>License</Header>
    <P>
      Subject to the terms and conditions of these Terms, Alo grants you a
      personal, non-exclusive, non- assignable, non-transferable, revocable
      license to access and use the Service, including a reasonable number of
      copies of any content, material or software made available by Alo, for
      non-commercial and personal use only.
    </P>
    <P>
      You must comply with all applicable laws when using the Service. Except as
      may be expressly permitted by applicable law or expressly enabled by a
      feature of the Service, you will not, and will not permit anyone else to:
      (a) store, copy, modify, or distribute any of the content made available
      on the Service; (b) compile or collect any content available on the
      Service as part of a database or other work; (c) use any automated tool
      (e.g., robots, spiders) or manual process to monitor, store, copy, modify,
      distribute, or resell any content from the Service; (d) frame or otherwise
      incorporate the Service or any portion of the Service as part of another
      website or service; (e) reproduce, duplicate, copy, sell, resell, or
      exploit for any commercial purposes any portion of the Service (including
      the display of third party advertising); (f) circumvent or disable any
      digital rights management, usage rules, or other security features of the
      Service, or any content available on the Service; (g) use the Service in a
      manner that threatens the integrity, performance, or availability of the
      Service; (h) remove, alter, or obscure any proprietary notices (including
      copyright notices) on any portion of the Service or any content available
      from the Service; or (i) include any personal or identifying information
      about another person in your User Content (defined below) without that
      person’s explicit consent.
    </P>

    <Header>User Content</Header>

    <P>
      In connection with registration for an Alo Moves Account, users may upload
      photos or other materials or information to the Service (“User Content”).
      You agree that you will not upload User Content to the Service unless you
      have created that content yourself, or you have permission from the
      copyright owner to do so.
    </P>

    <P>
      Communications in chat areas, forums, bulletin boards, communities,
      groups, or other public or common areas of the Service are not private
      communications. You should use caution when submitting any User Content
      that contains your personal information to a public or common area of the
      Service.
    </P>

    <P>
      For any User Content that you upload to the Service, you grant us and our
      subsidiaries, affiliates, and successors: (a) a worldwide, non-exclusive,
      royalty-free, fully-paid, perpetual, irrevocable, transferable, and fully
      sublicensable right to use, reproduce, modify, adapt, publish, translate,
      prepare derivative works of, distribute, publicly perform, and publicly
      display that User Content throughout the world in any media in connection
      with the Service and Alo’s business, including without limitation for
      promoting the Service; and (b) the right to use the name that you submit
      in connection with your User Content, if we choose. You retain all rights
      in your User Content, subject to the rights granted to Alo in these Terms.
      You may modify or remove your User Content via your Alo Moves Account or
      by terminating your Alo Moves Account, but Alo may, in accordance with the
      Privacy Policy and applicable laws, retain in its systems and use
      non-personally identifiable data that is derived from your User Content.
    </P>

    <P>
      You agree not to upload to the Service or otherwise post, transmit,
      distribute, or disseminate through the Service any material that: (i) is
      false, misleading, unlawful, obscene, indecent, lewd, pornographic,
      defamatory, libelous, threatening, harassing, hateful, inflammatory,
      abusive, abusive, inflammatory; (ii) encourages conduct that would be
      considered a criminal offense or gives rise to civil liability; (iii)
      breaches any duty toward or rights of any person or entity, including
      rights of publicity or privacy; (iv) contains corrupted data or any other
      harmful, disruptive, or destructive files; (v) advertises products or
      services competitive with Alo or its partners’ products and services, as
      determined by Alo in its sole discretion; or (vi) in Alo’s sole and
      absolute judgment, is objectionable, restricts or inhibits any person or
      entity from using or enjoying any portion of the Service, or which may
      expose Alo, its affiliates, or users to harm or liability of any nature.
    </P>
    <P>
      Although Alo has no obligation to screen, edit, or monitor any User
      Content, Alo reserves the right, and has absolute discretion, to remove,
      screen, edit, or disable any User Content at any time and for any reason
      without notice. You understand that by using the Service, you may be
      exposed to User Content that is offensive, indecent, objectionable, or
      inaccurate. We take no responsibility and assume no liability for any User
      Content, including any loss or damage to any of your User Content.
    </P>

    <Header>Licensed Content</Header>

    <P>
      Contents on the Service, including User Content and certain content
      offered through the Service which is available to you solely in connection
      with a Paid Subscription, has been licensed to Alo in accordance with
      various agreements between Alo and the persons or entities who own the
      rights to that content ("Licensed Content"). Licensed Content is protected
      by all applicable intellectual property laws.
    </P>

    <P>
      The Licensed Content is provided for informational purposes only. The
      Licensed Content is not intended to be a substitute for professional
      fitness or medical advice, diagnosis, or treatment. Always seek the advice
      of your physician or other qualified health or fitness provider with any
      questions you may have regarding a fitness regimen or medical condition.
      Do not disregard professional advice because of something you have read on
      the Service.
    </P>

    <P>
      Any opinion, advice, statement, service, offer, or other information that
      constitutes part of the Licensed Content expressed or made available via
      the Service are those of the respective authors or producers and not of
      Alo or its directors, officers, employees, agents, representatives,
      partners, or affiliates. Under no circumstances will Alo or its directors,
      officers, employees, agents, representatives, partners, or affiliates be
      held liable for any loss or damage caused by your reliance on any
      information obtained through the Service.
    </P>

    <Header>Copyright Infringement</Header>
    <P>
      Alo respects the intellectual property rights of others and asks you to do
      the same. It is Alo’s policy to terminate the access privileges of those
      who repeatedly infringe the copyright rights of others. If you believe
      that your work has been posted on the Service in a way that constitutes
      copyright infringement, please contact Alo’s copyright agent at the
      address below and provide the following information: (a) an electronic or
      physical signature of the person authorized to act on behalf of the owner
      of an exclusive right that is allegedly infringed; (b) a description of
      the copyright-protected work that you claim has been infringed; (c) the
      location on the Service of the material that you claim is infringing; (d)
      your address, telephone number, and email address; (e) a statement by you
      regarding your good faith belief that the disputed use is not authorized
      by the copyright owner, its agent, or the law; and (f) a statement by you,
      made under penalty of perjury, that the information in your notice is
      accurate and that you are the copyright owner or authorized to act on the
      copyright owner’s behalf. By submitting a copyright infringement notice,
      you acknowledge and agree that Alo or its copyright agent may forward the
      information you provide in this notice to the person who uploaded the
      allegedly infringing material.
    </P>

    <P>
      You may write to Alo’s designated agent for notice of copyright
      infringement at:
    </P>

    <P>
      Alo
      <br />
      Attn: Legal, Copyright Infringement
      <br />
      9830 Wilshire Blvd
      <br />
      Beverly Hills, CA 90212
      <br />
      <br />
    </P>

    <P>
      If you believe that your removed or disabled User Content is not
      infringing, or that you have the authorization or right to post and use
      that User Content from the copyright owner, the copyright owner's agent,
      or pursuant to law, you may send a counter-notice containing the
      information required by Section 512(g)(3) of the Digital Millennium
      Copyright Act (17 USC § 512(g)(3)). Alo or its copyright agent will
      forward your counter-notification to the party who submitted the original
      copyright infringement claim. If the original claimant does not file an
      action seeking a court order to restrain you from engaging in infringing
      activity related to the removed or disabled User Content within 10
      business days of receiving the counter-notice from Alo, then Alo may, in
      its sole discretion, reinstate the removed or disabled material.
    </P>

    <Header>Ownership; Trademarks</Header>

    <P>
      We, our affiliates, and our suppliers and licensors own all right, title,
      and interest, including all intellectual property rights, in and to the
      Service. Except for those rights expressly granted in these Terms, no
      other rights are granted, either express or implied, to you.
    </P>

    <P>
      Alo is a trademark of Alo, LLC. Other product, brand, and company names
      and logos used on the Service are the trademarks or registered trademarks
      of their respective owners. Any use of any of the marks appearing on the
      Service without our prior written consent or the owner of the mark, as
      appropriate, is strictly prohibited.
    </P>

    <Header>Communications with Users; User Meet-ups</Header>
    <P>
      When you join Alo, you understand and agree that you may receive
      communications from the other members of the Service. You also understand
      and agree that individual users are each acting independently and that no
      user is Alo’s representative or agent.
    </P>

    <P>
      The Service may include tools that enable users to arrange in-person
      meetings. Alo is not involved in arranging or supervising meetings, and
      has no control over who attends meetings or the actions of any individual
      at any meeting. Please use caution and good judgment when arranging or
      attending meetings.
    </P>

    <P>
      You agree that you bear all risk associated with any meeting you attend,
      and you agree to release us (and our officers, directors, shareholders,
      agents, employees, affiliates, subsidiaries, and third party partners)
      from claims, demands, and damages (actual and consequential) of every kind
      and nature, known and unknown, suspected and unsuspected, disclosed and
      undisclosed, now and in the future, arising out of or in any way connected
      with your use of the Service or any meeting you attend. You further waive
      any and all rights and benefits otherwise conferred by any statutory or
      non-statutory law of any jurisdiction that would purport to limit the
      scope of a release or waiver.
    </P>

    <Header>Alo Moves Paid Subscription</Header>

    <P>
      We provide numerous subscription options for the Service. Certain Service
      options are provided free-of- charge, while other options require payment
      before they can be accessed (the “Paid Subscriptions”) You can access a
      Paid Subscription by signing up to pay monthly or annually. The fees
      applicable to your Paid Subscription (the “Fees”) are governed by the
      specific Paid Subscription plan you agreed to. Alo makes no representation
      or warranty that the Fees are the lowest or best price at any given time
      during the term of your Paid Subscription.
    </P>

    <Header>Cooling off Period</Header>

    <P>
      You may terminate your Paid Subscription with immediate effect at any time
      within the period of 12 days (or such later date to the extent required by
      applicable law) after the date that you initially signed up for a Paid
      Subscription (the “Cooling Off Period”), without incurring any liability.
      If you choose to cancel your Paid Subscription within the Cooling off
      Period, we will provide you with a refund on all Fees previously paid by
      you for such Paid Subscription.
    </P>

    <Header>Processing of Payments</Header>

    <P>
      Alo uses a third-party payment processor (the “Payment Processor”) to link
      your credit card to your Alo Moves Account to the Service and process all
      payments made to Alo. The processing of payments or credits, as
      applicable, in connection with your use of the Service will be subject to
      the terms, conditions and privacy policies of the Payment Processor and
      your credit card issuer in addition to these Terms. Alo is not responsible
      for any errors by the Payment Processor. In connection with your use of
      the Service, Alo may obtain certain transaction details, which Alo will
      use solely in accordance with the Privacy Policy.
    </P>

    <Header>Recurring Billing</Header>

    <P>
      The Fees will be billed at the beginning of the paying portion of your
      membership cycle unless and until you cancel your membership. We
      automatically bill your payment method each month on the calendar day
      corresponding to the commencement of your paying membership. Upon notice
      to you, we reserve the right to change our billing timing, in particular,
      if your payment method has not successfully been charged. You will receive
      notice prior to any automatic renewal of your Paid Subscription in
      accordance with applicable law.
    </P>

    <Header>Refund Rights</Header>

    <P>
      If you cancel your Paid Subscription prior to its expiration, you will
      receive a refund of amounts you directly paid in advance. We will refund
      to you on a pro-rata basis, as applicable, calculated on a daily basis all
      Fees prepaid by you for remaining portions of the Paid Subscription.
    </P>

    <Header>Cancellations</Header>

    <P>
      You may cancel your Alo Moves Account at any time and for any reason. To
      cancel your Alo Moves Account, please visit your membership settings at
      https://www.alomoves.com/settings/membership. Following a cancellation,
      you will lose access to your previous Alo Moves Account history and all
      training plans you enrolled in during your membership upon the effective
      date of cancellation or in the case of a Paid Subscription, at the end of
      the then-current billing period. If you choose to subscribe to a new Paid
      Subscription, your billing date will update to the day you reactivate your
      Paid Subscription.
    </P>

    <Header>Third-Party Content</Header>

    <P>
      We may email or provide you coupons, offers, and other specials from third
      parties (collectively “Promotions”). Alo is not responsible for the
      redemption, errors, omissions, or expiration of Promotions. All Promotions
      featured as a part of the Service are subject to change without notice and
      we have no control over their legality or the ability of any merchant to
      complete the Promotion (including, the sale in accordance with the offer).
    </P>

    <P>
      The Service may contain links to web pages and content of third parties
      ("Third-Party Content") as a service to those interested in this
      information. We do not monitor, endorse, or adopt, or have any control
      over, any Third-Party Content. We undertake no responsibility to update or
      review any Third-Party Content and can make no guarantee as to its
      accuracy or completeness.
    </P>

    <P>
      Additionally, if you follow a link or otherwise navigate away from the
      Service, please be aware that these Terms will no longer govern. You
      should review the applicable terms and policies, including privacy and
      data gathering practices, of any Third-Party Content provider to which you
      navigate from the Service. You access and use Third-Party Content at your
      own risk.
    </P>

    <P>
      The Service may contain advertisements and promotions from third parties.
      Your business dealings or correspondence with, or participation in
      promotions of, advertisers other than us, and any terms, conditions,
      warranties, or representations associated with such dealings, are solely
      between you and such third party.
    </P>

    <Header>Music</Header>

    <P>
      Music on the Service is powered by Feed.fm. There is no affiliation,
      connection, association or endorsement of the products, goods or services
      displayed on this page or on any component of the Service between Alo and
      the copyright owners, featured recording artists and authors of the sound
      recordings (and the musical works embodied therein) transmitted through
      the Feed.fm player.
    </P>

    <Header>Assumption of Risk</Header>

    <P>
      Consumer protection laws in some jurisdictions, do not allow for service
      providers to limit their liability with respect to the consequences of
      their own acts or the acts of their representatives. If these laws apply
      to you, the exclusions or limitations in the following sections may not
      apply. In all other jurisdictions, and to the fullest extent permitted by
      applicable law:
    </P>

    <P>
      You understand that the Service is intended to be used in connection with
      athletic and fitness activities. You expressly acknowledge that engaging
      in athletic or fitness activities as part of your usage of the Service
      carries certain inherent and significant risks of property damage, bodily
      injury or death and that, to the extent permitted by applicable law, you
      voluntarily assume all known and unknown risks associated with these
      activities even if caused in whole or part by the action, inaction or
      negligence of Alo or by the action, inaction or negligence of others. You
      also expressly agree that Alo does not assume responsibility for the
      inspection, supervision, preparation, or conduct of any fitness activity,
      contest, group interaction, gathering, or event that utilizes the Service.
    </P>

    <P>
      Please consult your physician before using the Service in connection with
      any athletic and fitness activities. This is especially important for
      persons over age 35 or persons with pre-existing health problems.
      Discontinue any use of the Service or engaging in any such athletic or
      fitness activities that cause you pain, fatigue, discomfort, nausea,
      dizziness, or shortness of breath and consult a medical expert. Start
      slowly and at the level that is appropriate for you. Don’t overexert
      yourself. Take breaks periodically. Stop and rest if your muscles, joints,
      or eyes become tired or sore. Do not use the Service or engage in any such
      athletic or fitness activities under the influence of drugs or alcohol,
      and make sure your balance and physical abilities are sufficient for any
      movements and activities while using the Service.
    </P>

    <Header>Disclaimer of Warranties</Header>

    <P>
      Consumer protection laws in some jurisdictions do not allow for the
      limitations and exclusions of warranties. If these laws apply to you and
      to the extent they cannot be disclaimed or excluded, the exclusions or
      limitations in the following sections may not apply. In all other
      jurisdictions, and to the fullest extent permitted by applicable law:
    </P>

    <P>
      YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON
      AN "AS IS" AND "AS AVAILABLE" BASIS. ALO, ITS AFFILIATES, LICENSORS,
      SUPPLIERS, AND DISTRIBUTORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
      WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
      AND NON- INFRINGEMENT. ALO DOES NOT WARRANT UNINTERRUPTED USE OR OPERATION
      OF THE SERVICE OR THAT ANY DATA SENT BY OR TO YOU WILL BE ACCURATE,
      COMPLETE, TRANSMITTED IN UNCORRUPTED FORM, OR TRANSMITTED WITHIN A
      REASONABLE AMOUNT OF TIME. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DELAY OR
      LOSS OF ANY KIND THAT RESULTS FROM YOUR ACCESS OR USE OF THE SERVICE,
      INCLUDING LOSS OF ANY LOSS OR HARM TO YOUR MOBILE DEVICE. NO ADVICE OR
      INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM ALO, THROUGH OR
      FROM THE SERVICE, WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE
      TERMS.
    </P>

    <Header>Limitation of Liability</Header>

    <P>
      Consumer protection laws in some jurisdictions do not allow for the
      limitations of liability. If these laws apply to you and to the extent
      they cannot be disclaimed or excluded, the exclusions or limitations in
      the following sections may not apply. In all other jurisdictions, and to
      the fullest extent permitted by applicable law:
    </P>

    <P>
      NEITHER ALO NOR ITS AFFILIATES, LICENSORS, SUPPLIERS, OR DISTRIBUTORS WILL
      BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
      EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
      PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES EVEN IF ADVISED OF
      THE POSSIBILITY OF THESE DAMAGES, RESULTING FROM YOUR ACCESS OR USE OF THE
      SERVICE. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
      LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION
      MAY NOT APPLY TO YOU.
    </P>

    <P>
      EXCEPT AS OTHERWISE EXPRESSLY SET FORTH IN THESE TERMS, THE MAXIMUM TOTAL
      LIABILITY OF ALO, ITS AFFILIATES, LICENSORS, SUPPLIERS, AND DISTRIBUTORS
      TO YOU FOR ANY CLAIM RELATED TO THE SERVICE, WHETHER IN CONTRACT, TORT, OR
      OTHERWISE, IS THE GREATER OF THE AMOUNT YOU PAID FOR THE SERVICE OR $10.
      EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY,
      DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS
      UNDER THESE TERMS BETWEEN THE PARTIES. THIS ALLOCATION IS AN ESSENTIAL
      ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
      PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE
      TERMS. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED
      REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
    </P>

    <Header>Indemnity</Header>

    <P>
      You will defend (with the defense and legal counsel assumed and selected
      by Alo in its sole and absolute discretion), indemnify and hold Alo, its
      affiliates, directors, officers, agents, employees, and its licensors,
      suppliers, and Distributors harmless from any costs, damages, expenses,
      and liability caused by your use of the Service, your violation of these
      Terms, or your violation of any rights of a third party through use of the
      Service.
    </P>

    <Header>Access; Modifications to the Service</Header>

    <P>
      Alo does not provide you with the equipment to use the Service. You are
      responsible for all fees charged by third parties to access and use the
      Service (e.g., charges by ISPs or mobile carriers).
    </P>

    <P>
      Alo reserves the right to modify or discontinue, temporarily or
      permanently, all or a part of the Service without notice. Alo will not be
      liable to you or to any third party for any modification, suspension, or
      discontinuance of the Service.
    </P>

    <Header>No Support</Header>

    <P>
      Unless otherwise agreed by Alo in writing, Alo is not obligated to provide
      any support for the Service.
    </P>

    <Header>Privacy</Header>

    <P>
      Alo collects registration and other information about you through the
      Service. Our collection and use of this information is described in the
      Privacy Policy, which is incorporated into these Terms and available at
      http://www.alomoves.com/privacy
    </P>

    <Header>Feedback</Header>

    <P>
      If you provide feedback to Alo regarding the Service (“Feedback”), you
      acknowledge that the Feedback is not confidential and you authorize Alo to
      use that Feedback without restriction and without payment to you.
      Accordingly, you hereby grant to Alo a nonexclusive, royalty-free,
      fully-paid, perpetual, irrevocable, transferable, and fully sublicensable
      right to use the Feedback in any manner and for any purpose.
    </P>

    <Header>Changes to Terms</Header>

    <P>
      We reserve the right to modify these Terms from time to time, in our sole
      and absolute discretion. If we modify these Terms, we will indicate that
      we have done so on the Alo website at http://www.alomoves.com/terms or
      otherwise provide you notice. It is your responsibility to review these
      Terms regularly. Use of the Service after the effective date of a
      modification constitutes your acceptance of any modified Terms.
    </P>

    <P>
      Alo will send you an email notice of any material modification to these
      Terms before the modification becomes effective. You will have the
      opportunity to refuse the change or cancel the contract without cost,
      penalty by sending us a notice to that effect no later than 30 days after
      the effective date of the modification.
    </P>

    <Header>Termination</Header>

    <P>
      Consumer protection laws in some jurisdictions may not allow you to limit
      your recourses regarding legal proceedings. If these laws apply to you,
      and to the extent they cannot be disclaimed or excluded, the following
      provisions may not be applicable. In all other jurisdictions, and to the
      fullest extent permitted by applicable law:
    </P>
    <P>
      You may terminate your use of the Service at any time by terminating your
      Alo Moves Account and deleting any Alo software from your device or
      personal computer. Termination of your Alo Moves Account is your sole
      right and remedy with respect to any dispute with Alo regarding the
      Service or these Terms. Alo may suspend or terminate your access to the
      Service at any time, for any reason. If Alo suspects that you have
      violated any provision of these Terms, Alo may also seek any other
      available legal remedy. Your rights under these Terms will terminate
      automatically if you fail to comply with any of these Terms. Upon
      termination, you must destroy or delete any copy of Alo software in your
      possession.
    </P>

    <P>
      You remain solely liable for all obligations related to use of the
      Service, even after you have stopped using the Service. Neither Alo nor
      any of its licensors, suppliers, or publishers are liable to you or to any
      third party for any loss caused by any termination of the Service or
      termination of your access to the Service.
    </P>

    <Header>Assignment</Header>
    <P>
      These Terms, and any rights or licenses granted under these Terms, may not
      be transferred or assigned by you, but may be assigned by Alo without
      restriction. Any assignment attempted in violation of these Terms is void.
    </P>

    <Header>Governing Law; Venue</Header>

    <P>
      Consumer protection laws in some jurisdictions might require that your
      agreement be governed by the laws of your jurisdiction and heard by
      competent courts in your jurisdiction. If these laws apply to you, and to
      the extent they cannot be disclaimed or excluded, the following provisions
      may not be applicable. In all other jurisdictions, and to the fullest
      extent permitted by applicable law:
    </P>
    <P>
      These Terms will be governed by and construed in accordance with the laws
      of the State of California, excluding its conflict of law provisions. You
      agree that any judicial proceedings will be brought in and you hereby
      consent to the exclusive jurisdiction and venue in the state and federal
      courts in Los Angeles, California. Any dispute or controversy arising out
      of, relating to or concerning any aspect of these Terms shall be settled
      by binding arbitration to be held before a neutral arbitrator in Los
      Angeles, California, under the auspices of the American Arbitration
      Association. The arbitrator shall permit discovery sufficient to allow any
      party to the arbitration to take such discovery as is reasonably
      sufficient to allow that party to secure the information reasonably
      necessary to present their claim. The arbitrator may grant injunctions or
      other relief in such dispute or controversy. The decision of the
      arbitration shall be final, conclusive and binding on the parties to the
      arbitration. The award issued by the arbitrator shall be in writing and
      shall set forth the essential findings and conclusions that form the basis
      of the award. Judgment may be entered on the arbitrator's award in any
      court having jurisdiction. Except as may otherwise be provided by law,
      each party shall pay an equal share of the fees and costs charged by an
      arbitrator to hear the arbitration of this matter. Each party shall be
      solely responsible for payment of their respective attorney's fees and
      costs. The parties agree that all matters relating to any dispute which is
      the subject of the arbitration hereunder, including all submissions made
      to the arbitrator and the decision of the arbitrator, shall be treated as
      confidential by the parties and the parties shall cause any witnesses,
      counsel or professional advisers retained in connection with such
      arbitration to maintain all such matters in strict confidence. Claims may
      not be joined or consolidated in arbitration with other disputes brought
      by third parties, unless agreed to by Alo.
    </P>

    <Header>Claims</Header>

    <P>
      Consumer protection laws in some jurisdictions may not allow you to limit
      your time limitation to commence legal proceedings. If these laws apply to
      you, and to the extent they cannot be disclaimed or excluded, the
      following provisions may not be applicable. In all other jurisdictions,
      and to the fullest extent permitted by applicable law:
    </P>

    <P>
      YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE
      SERVICE MUST COMMENCE WITHIN ONE YEAR AFTER THE CAUSE OF ACTION ACCRUES.
      OTHERWISE, THAT CAUSE OF ACTION IS PERMANENTLY BARRED.
    </P>

    <Header>Waiver and Severability of Terms</Header>

    <P>
      The failure of Alo to exercise or enforce any right or provision of these
      Terms will not constitute a waiver of such right or provision. Any waiver
      of any provision of these Terms will be effective only if in writing and
      signed by Alo. If any provision of these Terms is found by a court of
      competent jurisdiction to be invalid, the parties nevertheless agree that
      the court should endeavor to give effect to the parties’ intentions as
      reflected in the provision, and the other provisions of these Terms remain
      in full force and effect.
    </P>

    <Header>Consent to Electronic Communications</Header>

    <P>
      By using the Service, you consent to receiving electronic communications
      from us. These communications may include notices about your Alo Moves
      Account and information concerning or related to the Service. You agree
      that any notices, agreements, disclosures, or other communications that we
      send to you electronically will satisfy any legal communication
      requirements, including that such communications be in writing.
    </P>

    <P>
      You are solely responsible for all fees charged by your telecommunications
      service provider or any other service provider related to your use of the
      Service, including without limitation any SMS / text messaging fees, data
      charges, and other fees.
    </P>

    <Header>Entire Agreement</Header>

    <P>
      These Terms, including any documents or agreements incorporated herein by
      reference, are the entire agreement between you and Alo regarding your use
      of the Service.
    </P>

    <Header>Contacting Alo</Header>

    <P>
      For questions, comments, complaints, or claims related to the Service,
      please contact us at:
    </P>

    <P>
      Alo Moves
      <br />
      9830 Wilshire Blvd
      <br />
      Beverly Hills, CA 90212
      <br />
      <a href='mailto: info@alomoves.com'>info@alomoves.com</a>
      <br />
      <br />
      Last Updated: October 18, 2023
    </P>
  </Fragment>
)
