import styled from 'styled-components'
import { space } from 'styled-system'

const Space = styled.div`
  ${space}
  height: 0px;
  grid-area: ${props => props.area || 'space'};
  display: ${props => props.hidden || ''}
`

export default Space
