import styled from 'styled-components'

import Box from '../../elements/Box'

const CarouselContainer = styled(Box)`
  position: relative;
  max-width: 100vw;
  
  & .swiper-slide {
    height: ${props => props.autoHeight && 'auto'};
    flex-shrink: 0;
    width: 100%;
    position: relative;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  & .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  
  & .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    box-sizing: content-box;
    transform: translate3d(0px, 0px, 0px);
  }

  & ${props => props.paginationClass || '.swiper-pagination-custom'} {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;

    .swiper-pagination-bullet {
      transition: all .25s ease-in-out;
      background: ${props => props.theme.colors.grey4};
      width: 8px;
      height: 8px;
      &.swiper-pagination-bullet-active {
        background: ${props => props.theme.colors.primary};
        width: 8px;
        height: 8px;
      }
    }      
  }
`

export default CarouselContainer
