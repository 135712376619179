import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Flex } from '../elements'
import { Header } from '../typography'

const Loading = ({ children, loading, error }) => (
  <Fragment>
    {/* TODO: Build out proper Error Component and reporting */}
    {error && (
      <Flex
        maxWidth='1320px'
        mx={['15px', null, 'auto']}
      >
        <Header variant='regular' textAlign='center'>We apologize, but there has been an error getting this information.</Header>
      </Flex>
    )}

    {loading && <Fragment></Fragment>}

    {(!loading && !error) && { ...children }}
  </Fragment>
)

Loading.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  error: PropTypes.bool
}

export default Loading
