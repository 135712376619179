import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box } from '../elements'
import { Metadata } from '../typography'

const Close = styled.div`
  position: absolute;
  top: 9px;
  right: 15px;
  padding: 5px;
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.error};
`

const Alert = ({ message, onClose, children, ...rest }) => {
  return (
    <Box
      position='relative'
      px='20px'
      py='14px'
      border='1px solid'
      borderColor='error'
      borderRadius='4px'
      bg='errorLight'
      {...rest}
    >
      {onClose && <Close onClick={onClose}>&times;</Close>}
      <Metadata color='error' pr={onClose ? '30px' : '0px'}>{message}</Metadata>
      {children}
    </Box>
  )
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node
}

export default Alert
