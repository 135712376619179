import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { system, compose } from 'styled-system'

import Box from './Box'
/**
|--------------------------------------------------
| This component is used to create Responsive SVG icons that resize according to media queries.
| It accepts the following props:
| height: An array of strings outlining the height at different breakpoints, according to Styled-System specifications.
| width: An array of strings outlining the width at different breakpoints, according to Styled-System specifications.
| SVG: The imported SVG node.
|
| Example Usage:
| import { ResponsiveSVG } from 'ui/acro'
| import { SVGIcon } from 'images'
|
| // React Code here...
| const sizeArray = {['20px', '24px', '32px']}
| <ResponsiveSVG height={sizeArray} width={sizeArray} SVG={SVGIcon}/>
|--------------------------------------------------
*/

const SVGContainer = styled(Box)((props) =>
  compose(
    system({
      fill: {
        property: 'fill',
        scale: 'colors'
      },
      stroke: {
        property: 'stroke',
        scale: 'colors'
      }
    }),
    css({
      '> svg > path': {
        stroke: props.color,
        fill: 'red'
      }
    }),
    {
      transition: 'fill 0.25s ease-in-out'
    }
  )
)

const ResponsiveSVG = ({
  SVG,
  width,
  height,
  svgProps,
  display = 'inline-block',
  ...rest
}) => {
  return (
    <SVGContainer width={width} height={height} display={display} {...rest}>
      <SVG height='100%' width='100%' {...svgProps} />
    </SVGContainer>
  )
}

ResponsiveSVG.propTypes = {
  SVG: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  svgProps: PropTypes.object
}

export default ResponsiveSVG
